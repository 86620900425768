@import '/src/assets/scss/variables';

.container {
  border: 2px solid red red;
  display: flex;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  padding: 0.375rem 0.75rem;
  width: 100%;
  gap: 0.5em;
  height: auto;
  flex: 1;

  &:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(115, 81, 123, 0.25);
    border-color: #b193b8;
  }

  input,
  textarea {
    flex: 1;
    appearance: none;
    border: none;
    outline: 0;
    resize: none;
    color: inherit;
  }

  textarea {
    min-height: 100px;
  }

  [data-element='counter'] {
    color: rgb(185, 185, 185) !important;
  }
}
