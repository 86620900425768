.container {
  animation: rotate 1.3s linear infinite;
}

@mixin animation-mixin($name) {
  @keyframes #{$name} {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@include animation-mixin(rotate);
