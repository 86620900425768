@font-face {
  font-family: 'Asap Bold';
  src: url(/assets/fonts/Asap-Bold.ttf);
  // font-display: swap;
}
@font-face {
  font-family: 'Asap BoldItalic';
  src: url(/assets/fonts/Asap-BoldItalic.ttf);
  // font-display: swap;
}
@font-face {
  font-family: 'Asap Italic';
  src: url(/assets/fonts/Asap-Italic.ttf);
  // font-display: swap;
}
@font-face {
  font-family: 'Asap Regular';
  src: url(/assets/fonts/Asap-Regular.ttf);
  // font-display: swap;
}

@font-face {
  font-family: 'Roboto Bold';
  src: url(/assets/fonts/Roboto-Bold.ttf);
  // font-display: swap;
}
@font-face {
  font-family: 'Roboto BoldItalic';
  src: url(/assets/fonts/Roboto-BoldItalic.ttf);
  // font-display: swap;
}
@font-face {
  font-family: 'Roboto Italic';
  src: url(/assets/fonts/Roboto-Italic.ttf);
  // font-display: swap;
}
@font-face {
  font-family: 'Roboto Regular';
  src: url(/assets/fonts/Roboto-Regular.ttf);
  // font-display: swap;
}

@font-face {
  font-family: 'OpenSans Bold';
  src: url(/assets/fonts/OpenSans-Bold.ttf);
  // font-display: swap;
}
@font-face {
  font-family: 'OpenSans BoldItalic';
  src: url(/assets/fonts/OpenSans-BoldItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'OpenSans Italic';
  src: url(/assets/fonts/OpenSans-Italic.ttf);
  // font-display: swap;
}
@font-face {
  font-family: 'OpenSans Regular';
  src: url(/assets/fonts/OpenSans-Regular.ttf);
  // font-display: swap;
}
