//Colors Theme
$default: #dad4dc !default;
$primary: #73517b !default;
$primary-dark: #67486e !default;
$primary-light: #9d85a2 !default;
$secondary: #00a6a3 !default;
$secondary-light: #56d8d4 !default;
$background: #f1edf1 !default;

$btn-hover: #f1edf1 !default;
//Colors Font
$font-default: #707070 !default;

// Colors Alerts
$success: #01a6a3 !default;
$error: #f7505e !default;
$warning: #ffca83 !default;
$info: #aaaaaa !default;

//Colors Status
$complete: #5fe3a1 !default;
$waiting: #6d8fdb !default;
$paused: #ff6b6b !default;

// Colors Escala
$zero: #ee3534 !default;
$one: #f64646 !default;
$two: #fe654f !default;
$three: #ff8943 !default;
$four: #fda83b !default;
$five: #ffc120 !default;
$six: #ebbd0f !default;
$seven: #c0cb0f !default;
$eight: #9dcd06 !default;
$nine: #79cc01 !default;
$ten: #2db102 !default;

// Colors Escala Ces
$one_ces: #ee3534 !default;
$two_ces: #fe654f !default;
$three_ces: #fda83b !default;
$four_ces: #ffc120 !default;
$five_ces: #c0cb0f !default;
$six_ces: #79cc01 !default;
$seven_ces: #2db102 !default;

$theme-colors: (
  'default': #dad4dc,
  'primary': #73517b,
  'primary-dark': #67486e,
  'primary-light': #9d85a2,
  'secondary': #00a6a3,
  'secondary-light': #56d8d4,
  'background': #f1edf1,
  'font-default': #707070,
  'success': #01a6a3,
  'error': #f7505e,
  'warning': #ffca83,
  'info': #aaaaaa,
  'zero': #ee3534,
  'one': #f64646,
  'two': #fe654f,
  'three': #ff8943,
  'four': #fda83b,
  'five': #ffc120,
  'six': #ebbd0f,
  'seven': #c0cb0f,
  'eight': #9dcd06,
  'nine': #79cc01,
  'ten': #2db102,
  'complete': #16d39a,
  'waiting': #6d8fdb,
  'defaultCheckbox': $primary,
  'paused': #ff6b6b,
  'one_ces': #ee3534,
  'two_ces': #fe654f,
  'three_ces': #fda83b,
  'four_ces': #ffc120,
  'five_ces': #c0cb0f,
  'six_ces': #79cc01,
  'seven_ces': #2db102,
  'gray-200': #eaecf4
);

// Override Bootstrap default variables here
// Do not edit any of the files in /vendor/bootstrap/scss/!

// Color Variables
// Bootstrap Color Overrides

$white: #fff !default;
$gray-100: #f8f9fc !default;
$gray-200: #eaecf4 !default;
$gray-300: #dddfeb !default;
$gray-400: #d1d3e2 !default;
$gray-500: #b7b9cc !default;
$gray-600: #858796 !default;
$gray-700: #6e707e !default;
$gray-800: #5a5c69 !default;
$gray-900: #3a3b45 !default;
$black: #000 !default;

$blue: #4e73df !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e74a3b !default;
$orange: #fd7e14 !default;
$yellow: #f6c23e !default;
$green: #1cc88a !default;
$teal: #20c9a6 !default;
$cyan: #36b9cc !default;

// Custom Colors
$brand-google: #ea4335;
$brand-facebook: #3b5998;

// Set Contrast Threshold
$yiq-contrasted-threshold: 195 !default;

// Typography
$body-color: $font-default !default;

$font-family-sans-serif: 'Asap Regular' !default;
$font-family-base: 'Asap Regular' !default;
$font-weight-light: 300 !default;
// $font-weight-base: 400;
$headings-font-weight: 400 !default;

// Shadows
$box-shadow-sm: 0 0.125rem 0.25rem 0 rgba($gray-900, 0.2) !default;
$box-shadow: 0 0.15rem 1.75rem 0 rgba($gray-900, 0.15) !default;
// $box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

// Borders Radius
$border-radius: 0.35rem !default;
$border-color: darken($gray-200, 2%);

// Spacing Variables
// Change below variable if the height of the navbar changes
$topbar-base-height: 3.1rem;
// Change below variable to change the width of the sidenav
$sidebar-base-width: 14rem;
// Change below variable to change the width of the sidenav when collapsed
$sidebar-collapsed-width: 6.5rem;

// Card
$card-cap-bg: $gray-100;
$card-border-color: $border-color;

// Adjust column spacing for symmetry
$spacer: 1rem;
$grid-gutter-width: $spacer * 1.5;

// Transitions
$transition-collapse: height 0.15s ease !default;

// Dropdowns
$dropdown-font-size: 0.85rem;
$dropdown-border-color: $border-color;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 1025px,
  lg: 1280px,
  xl: 1367px) !default;

$container-max-widths: (
  xs: 100%,
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 100%) !default;