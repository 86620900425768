.selectableRowContainer {
  display: flex;
  gap: 1em;
  padding: 1em;
  align-items: center;

  input,
  input.form-check-input {
    position: relative !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
}

.selectedRow {
  background-color: #f9f8fb !important;
}

.rowDescription {
  top: 9px;
}
