@include media-breakpoint-up(md) {
  .listagem-pesquisa {
    .pesquisa-texto {
      display: none;
    }

    .pesquisa-status-mobile {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {

  // #containerDashboad {
  //   padding-left: 0 !important;
  //   padding-right: 0 !important;
  // }
  .listagem-pesquisa {
    .list-group-item .col {
      padding: 0.5rem;
    }

    .pesquisa-data-descktop {
      display: none;
    }

    .idiomas {
      display: none;
    }

    .icon-search {
      display: none;
    }

    .criar-pesquisa {
      display: none;
    }

    .menu-item-pesquisa {
      display: block !important;
    }

    #busca-pesquisa {
      padding: 0.75rem;
    }

    .p-menu-mobile-opcoes {
      width: 12%;
      flex-basis: unset;
      text-align: center;
    }

    .busca-nome,
    .status_id,
    .taxa_respostas,
    .opcoes,
    .p-menu-desktop {
      display: none;
      flex-basis: unset;
    }

    .data_criacao,
    .nome {
      max-width: 52%;
      width: 52%;
      flex-basis: unset;
    }

    .num_respostas {
      max-width: 36%;
      width: 36%;
      padding-left: 0;
      flex-basis: unset;
    }

    .num_respostas_body {
      max-width: 36%;
      width: 36%;
      flex-basis: unset;
    }
  }

  .resultados-visualizador {
    display: block !important;
  }
}

.listagem-pesquisa {
  .pesquisa-texto {
    text-align: right;

    .btn {
      padding: 0;
    }
  }
}