.stepbarMainContainer {
  display: flex;
  gap: 1em;

  align-items: center;
  justify-content: center;
}

.stepbar__steps {
  display: flex;

  align-items: center;
  justify-content: center;
  gap: 1em;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    width: 10rem;
    height: 2px;
    background-color: var(--primary);
  }

  span {
    line-height: 1;
    padding: 0.5rem !important;
    display: flex;
    background: var(--primary);
    width: 4px;
    height: 4px;
    font-size: 12px;
    border-radius: 1em;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    color: #fff;
  }

  p {
    margin: 0;
    padding: 0;
    font-weight: bold;
    color: var(--primary);
  }
}

.stepDone {
  color: var(--secondary);
  span {
    background: var(--secondary);
  }

  p {
    color: var(--secondary);
  }
  &:not(:last-child)::after {
    background: var(--secondary);
  }
}

.stepIsDisabled {
  color: var(--info);

  span {
    background: var(--info);
  }

  p {
    color: var(--info);
  }

  &:not(:last-child)::after {
    background: var(--info);
  }
}

.stepIsActive {
  color: var(--primary);
  font-weight: bold;

  span {
    background: var(--primary);
  }

  p {
    color: var(--primary);
  }

  &:not(:last-child)::after {
    background: var(--primary);
  }
}

.tableHeader {
  border: 1px solid #DAD4DC;
  align-items: center;
  background-color: #f9f8fb;
  border-radius: 3px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 10px 8px;
  margin: 0px !important;
}

.tableRow {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border: 1px solid #DAD4DC;
  background: white;
  padding: 10px 16px;
}

.tableRow:last-of-type {
  border-radius: 0px 0px 3px 3px;
}

.selectedRow {
  background-color: #f9f8fb !important;
}
