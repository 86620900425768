@import url('https://fonts.googleapis.com/css2?family=Asap:wght@500&display=swap');

#root {
  height: 100vh;
}
.conteudo-pagina {
  background-color: #f1edf1;
  padding-top: 2.5rem;
  padding-bottom: 3.5rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.conteudo-pagina-sem-pt {
  background-color: #f1edf1;
}

body,
button,
span,
.tabela-responsiva,
.form-control {
  font-family: 'Asap Regular';
  font-size: 15px;
}
a.text-secondary {
  text-decoration: underline;
}
a.text-secondary:hover {
  text-decoration: underline;
}

.table {
  color: var(--font-default);
  text-align: center;
}
.btn {
  border-radius: 6px;
  font-size: 1rem;
}
.btn-default {
  color: white;
}
.error-feedback {
  display: block;

  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;

  color: #e74a3b;
}
.scroll-vertical {
  overflow-y: scroll;
}
.hv-90 {
  height: 90vh;
}

.text-small {
  font-size: 13px !important;
}

.text-creator {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-nome-pesquisa {
  font-size: 18px !important;
}

.pointer {
  cursor: pointer;
}

.horizontal-line {
  border-bottom: 1px solid var(--primary);
}

.list-info {
  color: #aaaaaa;
}

.titulo-negrito {
  font-family: 'Asap';
  font-weight: 700;
}

.list-texto-vermelho,
.list-texto-vermelho:hover {
  color: #ff6b6b;
}
#containerDashboad
  > div
  > div:nth-child(2)
  > div
  > ul
  > li:nth-child(1)
  > div
  > div:nth-child(4)
  > a
  .close
  span {
  font-size: 24px;
}
div#toast-container > div.toast {
  line-height: 20px;
}
#containerDashboad.smooth {
  scroll-behavior: smooth;
}
form#form-modal-metodo-envio label {
  font-size: 18px;
}
.header-text {
  font-size: 18px;
}
span.count-respostas,
.count-respostas,
.date-envio-list {
  font-size: 15px;
  font-weight: 700;
  margin-top: 5px;
  display: block;
}

.link-opinion-box {
  text-decoration: none !important;
  display: contents;
  background: none !important;
  cursor: text !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #56d8d4;
  text-decoration: underline;
  cursor: pointer;
}
.link-opinion-box:hover {
  color: #56d8d4;
}
.close-btn-modal {
  font-size: 18px;
  color: var(--primary);
  cursor: pointer;
}
.close-btn-modal-pergunta {
  font-size: 18px;
  color: white;
  cursor: pointer;
  margin-right: 15px;
}
.ios-app-body-wrapper select {
  -webkit-appearance: none;
}
.text-green {
  color: var(--secondary);
}
.autocomplete .menu {
  margin-top: 5px;
  border: 1px solid #d1d3e2;
  background-color: #fff;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
}

.mrg-4 {
  margin-right: 4rem;
}

.autocomplete .menu .item-highlighted {
  color: #000;
}

.autocomplete .menu .item {
  padding-top: 5px;
  padding-bottom: 5px;
}

.autocomplete .menu .item:hover {
  cursor: pointer;
}

.relatorio-date-picker .input-group {
  min-width: 120px;
}

.loading {
  z-index: 9998;
  position: absolute;
  background: black;
  opacity: 0.2;
}

.spinner {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.spinner > div {
  width: 3rem;
  height: 3rem;
}
.box-white-container {
  background-color: white;
  border: 1px solid #ddd7df;
  border-radius: 0.35rem;
}
.codigos-integracoes-input {
  background-color: white !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.resposta-pergunta .opcaoRespostaMultipla,
.resposta-pergunta .opcaoRespostaMultipla input,
.resposta-pergunta .opcaoRespostaMultipla label {
  cursor: pointer;
}

tr,
td,
th {
  font-weight: inherit;
}

/* Estilo para cabeçalho do tabela*/
.table-list-header {
  background-color: #f9f8fb;
  border-radius: 3px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 10px 8px;
}

.table-list-item {
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
  border: 1px solid #f9f8fb;
  background: white;
  padding: 10px 16px;
}

/*  Media query mobile */

@media screen and (max-width: 1024px) {
  .conteudo-pagina {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .hide-on-mobile {
    display: none !important;
  }
}

.underline {
  text-decoration: underline !important;
}
