.selectableRowContainer {
  display: flex;
  gap: 1em;
  padding: 1em;
  align-items: center;

  input,
  input.form-check-input {
    position: relative !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
}

.modalContainer {
  display: flex;
  justify-content: center;

  > div {
    width: 100%;
    max-width: 620px !important;
    background: #fff;
  }
}

.selectedRow {
  background-color: #f9f8fb !important;
}
