// .btn-circle {
//   border-radius: 100%;
//   height: 2.5rem;
//   width: 2.5rem;
//   font-size: 1rem;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   &.btn-sm {
//     height: 1.8rem;
//     width: 1.8rem;
//     font-size: 0.75rem;
//   }
//   &.btn-lg {
//     height: 3.5rem;
//     width: 3.5rem;
//     font-size: 1.35rem;
//   }
// }

// .btn-icon-split {
//   padding: 0;
//   overflow: hidden;
//   display: inline-flex;
//   align-items: stretch;
//   justify-content: center;
//   .icon {
//     background: fade-out($black, 0.85);
//     display: inline-block;
//     padding: $btn-padding-y $btn-padding-x;
//   }
//   .text {
//     display: inline-block;
//     padding: $btn-padding-y $btn-padding-x;
//   }
//   &.btn-sm {
//     .icon {
//       padding: $btn-padding-y-sm $btn-padding-x-sm;
//     }
//     .text {
//       padding: $btn-padding-y-sm $btn-padding-x-sm;
//     }
//   }
//   &.btn-lg {
//     .icon {
//       padding: $btn-padding-y-lg $btn-padding-x-lg;
//     }
//     .text {
//       padding: $btn-padding-y-lg $btn-padding-x-lg;
//     }
//   }
// }

.dropdown {

  .btn-outline-primary,
  .btn-outline-primary:hover,
  .btn-outline-primary:focus,
  .btn-outline-primary:active {
    color: $gray-700  !important;
    background-color: white !important;
    border-color: $default  !important;
  }

  .btn-outline-primary:focus,
  .btn:focus {
    box-shadow: none;
  }
}

.btn-select-nps,
.btn-select-nps .btn-outline-primary,
.btn-select-nps .btn-outline-primary:hover,
.btn-select-nps .btn-outline-primary:focus,
.btn-select-nps .btn-outline-primary:active {
  color: $gray-700  !important;
  background-color: white !important;
  border-color: $default  !important;
}

.btn-select-ces,
.btn-select-ces .btn-outline-primary,
.btn-select-ces .btn-outline-primary:hover,
.btn-select-ces .btn-outline-primary:focus,
.btn-select-ces .btn-outline-primary:active {
  color: $gray-700  !important;
  background-color: white !important;
  border-color: $default  !important;
}

.btn-select-nps,
.btn-select-ces,
.btn-outline-primary:focus,
.btn:focus {
  box-shadow: none;
}

.dropdown.disabled {
  .btn-outline-primary {
    background-color: rgb(206, 212, 218) !important;
  }
}

.btn-secondary.disabled {
  color: #fff;
  background-color: #cccccc;
  border-color: #cccccc;
}
