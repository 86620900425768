.tableHeader {
  border: 1px solid #DAD4DC;
  align-items: center;
  background-color: #f9f8fb;
  border-radius: 3px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 10px 8px;
  margin: 0px !important;
}
