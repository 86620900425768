.criarCategoriaMainContainer {
  gap: 2em;
  display: flex;
  padding: 1.5em !important;
}

.divider {
  display: block;
  min-height: 100%;
  width: 2px;
  background: #eee;
}
