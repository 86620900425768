.modal {
  >div {
    background: #fbf9fb;
    border: 1px solid #fbf9fb;
    border-radius: 0.3rem;

  }
}

.header {
  display: grid;
  grid-template-columns: auto 35px;
  gap: 10px;
  align-items: center;

  color: var(--primary) !important;
  padding: 1rem 1rem;

  h5 {
    margin: 0;
  }

  button {
    color: var(--primary) !important;
  }
}