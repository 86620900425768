.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 0;
  border-bottom: 1px solid #e3e6f0;

  span {
    color: var(--primary);
  }

  button {
    border: none;
    background: transparent;
    color: var(--secondary);

    &:hover {
      color: var(--secondary);
      text-decoration: underline;
    }
  }
}

.modal {
  justify-content: center;

  > div {
    max-width: 621px;
  }
}

.modalFooter {
  button+button {
    margin-left: 15px;
  }
}
