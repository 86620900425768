.sugestaoCategoriaContainer {
  flex: 1;

  min-width: 250px;
}

.DivLoading {
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;

  div {
    width: 50px;
    height: 50px;
    margin: 20px !important;
  }

  span {
    text-align: center;
  }
}