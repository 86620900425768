/* Checkbox */
.custom-checkbox .custom-control-label::before {
  width: 20px;
  height: 20px;
  border: 1px solid var(--primary);
  border-radius: 3px !important;
  top: 2px;
}

.opcaoRespostaMultipla .custom-checkbox .custom-control-label::before {
  width: 20px;
  height: 20px;
  border: 1px solid var(--defaultCheckbox);
  border-radius: 3px !important;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.opcaoRespostaMultipla
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: #fff;
  border-color: var(--defaultCheckbox);
}

.custom-checkbox .custom-control-label::after {
  width: 1.3rem;
  height: 1.3rem;
  top: 2px;
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(115, 81, 123, 0.5);
  border: rgba(115, 81, 123, 0.5) !important;
}

.opcaoRespostaMultipla .custom-checkbox .custom-control-label::after {
  width: 1.3rem;
  height: 1.3rem;
  margin: 1px 0 0 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Radio */
.custom-radio.resposta {
  border: 1px solid var(--colorMainRadio);
  border-radius: 3px;
  background-color: white;
}

.custom-radio.no-interactive {
  border: 1px solid var(--primary);
  border-radius: 3px;
  background-color: white;
}

.custom-radio.resposta.active {
  background-color: var(--colorMainRadio);
  color: white;
}

.custom-radio .custom-control-label {
  display: flex;
}

.input-label-preview-widget label {
  display: flex !important;
  border-radius: 5px !important;
  justify-content: flex-start !important;
  align-items: center !important;
  min-width: 250px !important;
  cursor: pointer !important;
  margin-bottom: 4px !important;
}

.input-label-preview-widget label span {
  width: 100%;
  padding-left: 30px;
  text-align: start;
}

.input-label-preview-widget-desktop label {
  display: flex !important;
  border-radius: 5px !important;
  justify-content: flex-start !important;
  align-items: center !important;
  cursor: pointer !important;
}

.input-label-preview-widget-desktop label span {
  width: 100%;
  padding-left: 30px;
  text-align: start;
}

.custom-radio.resposta .custom-control-label::before {
  width: 20px;
  height: 20px;
  border: 1px solid var(--colorMainRadio);
  position: relative;
  left: 0;
  top: 0.15rem;
  margin-right: 10px;
  margin-bottom: 3px;
}

.custom-radio.no-interactive .custom-control-label::before {
  width: 20px;
  height: 20px;
  border: 1px solid var(--primary);
  position: relative;
  left: 0;
  top: 0.15rem;
  margin-right: 10px;
  margin-bottom: 3px;
}

.custom-radio.resposta
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: transparent !important;
  box-shadow: none;
  background-color: var(--colorMainRadio) !important;
  border: 3px solid white;
}

.custom-radio.no-interactive
  .custom-control-input:disabled
  ~ .custom-control-label::before {
  background-color: var(--white);
}

.custom-radio.resposta .custom-control-label::after,
.custom-radio.resposta
  .custom-control-input:checked
  ~ .custom-control-label::after {
  content: unset !important;
}

.custom-file-label::after {
  content: 'Selecionar arquivo' !important;
  background: var(--primary);
  color: white;
  border: 1px solid var(--primary);
}

.select-caret-down-alias {
  position: absolute;
  right: 5%;
  top: 35%;
}

.input-file-custom-container span {
  font-size: 15px;
}

.input-file-custom-container {
  width: 100%;
  height: 100%;
}

.custom-radio.radioWidget {
  border: 1px solid;
  border-radius: 3px;
}

.bordaUnicaWidget {
  color: var(--defaultRadioWidget);
  border: none !important;
}

.custom-radio.radioWidget .custom-control-label::before {
  background-color: var(--defaultRadioWidget);
  width: 20px;
  height: 20px;
  border: 1px solid;
  position: relative;
  left: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.custom-radio.radioWidget .custom-control-label::after,
.custom-radio.radioWidget
  .custom-control-input:checked
  ~ .custom-control-label::after {
  content: unset !important;
}

.opcaoRespostaMultipla
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: transparent;
  /* border-color: var(--defaultCheckbox); */
  max-width: 170px;
}

.inputMultiplaWidget .custom-control-label::before {
  background-color: var(--defaultCheckbox) !important;
}

.checked-inputMultiplaWidget .custom-control-label::after {
  background-color: var(--defaultCheckboxBorder) !important;
  border: solid 1px var(--defaultCheckbox) !important;
  border-radius: 3px !important;
  background-image: var(--defaultCheckboxBackgroundImage) !important;
}

.inputRemoverLogotipoLayout {
  position: relative;
  bottom: 10px;
  cursor: pointer;
}

.label-input-file {
  display: block !important;
}

.container-input-counter {
  display: flex;
}

.container-input-counter input {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.container-input-counter input:focus {
  transition: none;
  box-shadow: none;
  border-color: #d1d3e2;
}

.container-input-counter span {
  border-left: none;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  height: calc(1.5em + 0.75rem + 2px);
  background: white;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
}

.container-input-counter span:focus {
  border-color: #b193b8;
}

.disabled-with-click .custom-control-label::before {
  border: 1px solid #cccccc;
}

.disabled .custom-control-label::before {
  border: 1px solid #cccccc;
}
