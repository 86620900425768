.container {
  height: 36px;
  font-size: 15px;
  border-radius: 6px;
  line-height: normal;
}

.buttonResponsive {
  width: 100%;
  max-width: 200px;
}

.buttonEscala:first-child {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.buttonEscala:last-child {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
