.options {
  display: flex;
  justify-content: right;

  width: 100%;

  button {
    width: min-content;
    white-space: nowrap;
  }
}

.buttonSave {
  color: var(--secondary);
  text-decoration: underline;
  margin-right: 10px;
}

.modal {
  max-width: 550px !important;
}
