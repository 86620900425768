.container {
  padding: 1.25rem !important;
  display: flex;
  flex-direction: column;

  background: #fff !important;
}

.description {
  margin-bottom: 50px;
}
