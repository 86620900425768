.border-primary-main {
  border: 1px solid $primary;
  border-radius: 3px;
  background-color: white;
}
.border-primary-strong {
  border: 4px solid $primary !important;
  border-radius: 3px;
}
.border-primary-shadow {
  border: 1px solid $primary;
  border-radius: 3px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.border-default {
  border: 1px solid $default;
  border-radius: 3px;
  background-color: white;
}
.border-default-shadow {
  border: 1px solid $default;
  border-radius: 3px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.border-default-dashed {
  border: 1px dashed $default;
  border-radius: 3px;
  background: white;
}

.border-danger {
  border: 1px solid #e74a3b;
  border-radius: 3px;
  background-color: white;
}

.box-primary-shadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.box-primary-shadow-strong {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.header-primary {
  border-top-width: 0px;
  background-color: var(--primary-light);
  color: white;
  text-align: center;
}

.header-light {
  background-color: #f9f8fb !important;
  color: var(--primary) !important;
}

.custom-file-input,
.custom-file-label {
  overflow: hidden;
}

.btn-simple {
  border: none;
  background: transparent;
  padding: 0;
}
