.tagSelectorMainContainer {
  display: flex;
  gap: 1rem;
}

.tagsContainer {
  display: flex;
  gap: 0.5rem;
  flex: 1;
  flex-wrap: wrap;
}

.tags {
  border-radius: 1rem;
  padding: 0.125rem 0.75rem;
  background: var(--primary);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  font-size: 13px;
  height: fit-content;

  cursor: pointer;
}
